<template>
  <div style="overflow-y: auto; -webkit-overflow-scroll: touch">
    <!--begin::Content header-->

    <!--end::Content header-->

    <div
      :style="$root.mobile ? 'width:80vw; margin-top: -2vh; ' : 'width:20vw'"
    >
      <!--begin::Signin-->
      <div v-if="!$root.mobile" class="text-center mb-2 mb-lg-20">
        <!--begin: Aside header -->
        <div v-if="this.$root.mobile" class="flex-column-auto">
          <img class="mb-3" src="media/logos/doctory-full.png" width="60%" />
          <p class="text-muted font-size-h5 font-weight-semi-bold">
            {{ $t("AUTH.LOGIN.MSG") }}
          </p>
        </div>
        <!--end: Aside header -->
        <div v-else>
          <h3 class="font-size-h1">{{ $t("AUTH.LOGIN.BUTTON") }}</h3>
          <p class="text-muted font-weight-semi-bold">
            {{ $t("AUTH.LOGIN.MSG") }}
          </p>
        </div>
      </div>
      <div
        v-else
        class="flex-column-fluid d-flex flex-column justify-content-center"
        style="height: 40vh"
      >
        <lottie-animation
          :animationData="require('@/assets/animation/lf20_px2l8tdq.json')"
          :loop="true"
          style="margin-bottom: 4vh; height: 100%"
          :class="'animate_en_mobile'"
        />
      </div>

      <v-form v-model="formvalid">
        <v-text-field
          hide-details="auto"
          background-color="rgba(165, 203, 239, 0.42)"
          filled
          rounded
          color="primary"
          :rules="emailRules"
          required
          v-model="form.email"
          :label="$t('AUTH.LOGIN.EMAIL')"
          value=" "
        />
        <v-text-field
          hide-details="auto"
          background-color="rgba(165, 203, 239, 0.42)"
          class="mt-3"
          type="password"
          :rules="[(v) => !!v || $t('AUTH.LOGIN.PASSVALIDATION')]"
          required
          filled
          rounded
          color="primary"
          v-model="form.password"
          :label="$t('AUTH.LOGIN.PASSWORD')"
          value=" "
        />
        <v-snackbar v-model="snackbar" multi-line timeout="5000" color="error">
          {{ errors }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <div class="mt-5" style="display: flex">
          
          <router-link
          v-if="$util.getSettings().open_registration"
            style="width: 48%"
            :class="$root.lang == 'ar' ? 'ml-1' : 'mr-1'"
            :to="{ name: 'register' }"
          >
            <v-btn
              large
              rounded
              outlined
              block
              color="primary"
              style="border: 2px solid"
              >{{ $t("AUTH.REGISTER.TITLE") }}</v-btn
            >
          </router-link>
          <v-btn
            @click="submit"
            :disabled="submitted && !errors.length"
            :loading="submitted && !errors.length"
            rounded
            :width="$util.getSettings().open_registration? '48%' : '97.5%'"
            
            :class="$root.lang == 'ar' ? 'mr-1' : 'ml-1'"
            large
            elevation="0"
            color="primary"
            >{{ $t("AUTH.LOGIN.BUTTON") }}</v-btn
          >
        </div>
        <div class="mt-5">
          <div
            @click="opendocreg = !opendocreg"
            class="text-primary font-weight-bold font-size-3 ml-2"
            style="float: left"
          >
            {{ $t("AUTH.GENERAL.SIGNUP_HERE") }}
          </div>
          <a
            href="#"
            class="text-dark-60 text-hover-primary ml-2"
            style="float: right; margin-right: 8px"
            id="kt_login_forgot"
          >
            {{ $t("AUTH.FORGOT.TITLE") }}
          </a>
        </div>
      </v-form>

      <!--end::Signin-->
    </div>
    <v-bottom-sheet v-if="$root.mobile" v-model="opendocreg">
      <v-card class="" elevation="0">
        <v-toolbar elevation="0" class="text-primary">
          <v-toolbar-title>
            {{ $t("AUTH.REG_STEPS.doc.title") }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon dark color="primary" @click="opendocreg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="py-5 pb-10 text-center text-muted text-size-h2">
          {{ $t("AUTH.REG_STEPS.doc.body") }}
        </div>
      </v-card>
    </v-bottom-sheet>
    <v-dialog v-if="!$root.mobile" v-model="opendocreg" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("AUTH.REG_STEPS.doc.title") }}
        </v-card-title>

        <v-card-text>
          {{ $t("AUTH.REG_STEPS.doc.body") }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary darken-1" text @click="opendocreg = false">
            {{ $t("POPUPS.okay") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import LottieAnimation from "lottie-web-vue";

export default {
  name: "login",

  components: {
    LottieAnimation,
  },
  data() {
    return {
      languageFlag: "",
      opendocreg: false,
      snackbar: false,
      animeflag: true,
      // Remove this dummy login info
      formvalid: false,
      emailRules: [
        (v) => !!v || this.$t("AUTH.LOGIN.EMAILVALIDATION"),
        (v) => /.+@.+/.test(v) || this.$t("AUTH.LOGIN.EMAILVALIDATION"),
      ],
      submitted: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    this.$root.loader = false;
  },
  methods: {
    hideanimation() {
      this.animeflag = false;
    },
    showanimation() {
      this.animeflag = true;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    submit() {
      if (this.formvalid) {
        this.submitted = true;
        const email = this.form.email;
        const password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then(async () => {
            await this.$user.fetchCurrentUser().then(async () => {
              let user = this.$user.getCurrentUser();
              await this.$user.logAccess(this.$util.getDeviceInfo())
              this.$util.startUpDS(user);
              if (user.role.id == 4) {
                //loading mf
                await this.$file.getItemByID(user.id, true).then((data) => {
                  console.log("mdeical file is", data);
                  this.$root.mf = data.id;
                });
              } else if (user.role.id == 3) {
                console.log("doctor role is", user.role.id, "user is", user);
                await this.$doctor.getItemByID(user.id, true).then((data) => {
                  console.log("doctor is", data);
                  this.$root.docID = data.id;
                });
              }
              this.loader = false;
            });
            this.$router.push({ name: "dashboard" });
          });
      }
    },
  },
  watch: {
    errors: function (newerr) {
      if (newerr.length > 0) {
        this.snackbar = true;
      }
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
<style>
.v-application ul {
  padding-left: 0px !important;
}
.theme--light.v-text-field--solo-inverted > .v-input__control > .v-input__slot {
  padding: 15px 18px !important;
  background-color: #f3f6f9 !important;
  color: rgb(63, 66, 84) !important;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot {
  background-color: rgb(232, 240, 254) !important;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot {
  background-color: transparent !important;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  input,
label {
  color: rgb(63, 66, 84) !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3f6f9 inset;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgb(232, 240, 254) inset;
}

.animate_ar_mobile {
  margin-right: auto;
  margin-left: auto;
  z-index: 0;
  clip-path: circle(45%);
  background-color: #f8d000;
}

.animate_en_mobile {
  margin-right: auto;
  margin-left: auto;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
  z-index: 0;
}
</style>
